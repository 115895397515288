/******************************************************************

Stylesheet: Login Stylesheet

This stylesheet is loaded is only on the login page. This way you can style
the login page. It won't affect any other page, admin or front-end.

Make sure functions/login.php is activated in your functions.php file.

This stylesheet is turned off by default.

For more info, check out the codex:
http://codex.wordpress.org/Creating_Admin_Themes

******************************************************************/

body.login {
	
}

#login {
	
}

// Change the login logo
.login h1 a {
	background: url(../images/login-logo.png) no-repeat top center;
	width: 326px;
	height: 67px;
	text-indent: -9999px;
	overflow: hidden;
	padding-bottom: 15px;
	display: block;
	background-size: contain;
}

form {

}

body form .input {

}

// Login Button Style
#wp-submit {
	
}
